import './style.styl'
import { sel, selAll, wfSliderArrows, wfTabs } from './utils'
import Home from './home'
import Form from './form'
import Thank from './thank'
import Intake from './intake'
import Intake2 from './intake2'
import Intake3 from './intake3'
import Partner from './partner'
import Ny from './ny'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { lenis } from './lenis'

lenis.on('scroll', ScrollTrigger.update)
gsap.ticker.add((time) => {
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0)

document.querySelectorAll('a[href^="#"]').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault()
    const id = el.getAttribute('href')?.slice(1)
    if (!id) return
    const target = document.getElementById(id)
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' })
    }
  })
})
const _dataPage = sel('.page-wrapper') || sel('body')
const dataPage = _dataPage?.getAttribute('data-page')
switch (dataPage) {
  case 'home':
    Home()
    break
  case 'error':
    Error()
    break
  case 'thank':
    Thank()
    break
  case 'form':
    Form()
    break
  case 'intake':
    Intake()
    break
  case 'intake2':
    Intake2()
    break
  case 'partner':
    Partner()
    break
  case 'ny':
    Ny()
    break
  default:
    console.log('unknown data-page:', dataPage)
}

wfSliderArrows()
wfTabs()

// steps

const stepsSec$a = selAll('.steps-sec')
stepsSec$a.forEach((el, i) => {
  const steps$ = el.querySelector('.how__step-col')
  const steps$a = el.querySelectorAll('.how__step__item')
  const firstStep = steps$a[0]
  const lastStep = steps$a[steps$a.length - 1]
  const stepsTl = gsap.timeline({ defaults: { ease: 'none' }, paused: true })

  const color = getComputedStyle(document.documentElement).getPropertyValue('--color--orange')

  steps$a.forEach((step, i) => {
    const num$ = step.querySelector('.how__num__bg')
    stepsTl.to(num$, { background: color, scale: 1.1, duration: 0.5 }, i)
  })
  const tlDuration = stepsTl.duration()

  stepsTl.fromTo(steps$, { '--progress': 'calc(0% + 0rem)' }, { '--progress': 'calc(100% + 5rem)', duration: tlDuration }, 0)

  ScrollTrigger.create({
    animation: stepsTl,
    trigger: firstStep,
    endTrigger: lastStep,
    start: 'top 75%',
    end: 'bottom 50%',
    // markers: true,
    scrub: 1,
  })
})

const modal$ = sel('.mod.is--persona')
if (modal$) {
  const steps$a = modal$.querySelectorAll('[sf-step]')

  steps$a.forEach((step) => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target.style.display === 'flex') {
          const step$ = entry.target
          steps$a.forEach((el) => {
            el.classList.remove('is--active')
          })
          step$.classList.add('is--active')
          const formFieldWrapper$a = step$.querySelectorAll('.form_field-wrapper')
          formFieldWrapper$a.forEach((el) => {
            const hasRequiredInput = el.querySelector('[sf-radio-required], [req], [required]')
            if (!hasRequiredInput) return
            const input$ = el.querySelector('input, select, textarea')
            if (input$.value === '') {
              input$.classList.add('is--invalid')
            }
            input$.addEventListener('input', function () {
              // const valid = this.checkValidity()
              // const valid = this.value

              const valid = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$/.test(this.value)

              // el.classList.toggle('is--valid', valid)
              if (!valid) {
                input$.classList.add('is--invalid')
              } else {
                input$.classList.remove('is--invalid')
              }
            })
          })
        }
      })
    })
    observer.observe(step)
  })
}
