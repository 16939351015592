import Splide from '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import { addStaticSplideClasses } from './utils'

export default function Ny() {
  const name = 'open-house'
  addStaticSplideClasses(name + '__slider')
  const splide = new Splide('.' + name + '__slider', {
    autoplay: true,
    gap: '3rem',
    perPage: 2,
    perMove: 1,
    type: 'loop',
    pagination: false,
    arrows: false,
    speed: 2500,
    interval: 3000,
    easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    breakpoints: {
      768: {
        perPage: 1,
      },
    },
  })
  splide.mount()
}
